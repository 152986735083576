import useTranslation from './useTranslation';
import { useSelector } from 'react-redux';

const useSubheaderMenu = () => {
  const { TRANSLATIONS } = useTranslation();
  const userCatalog = useSelector((state) => state?.user?.userCatalogs)?.[0];
  const PERMISSIONS = useSelector((state) => state.permissions);
  const isLoggedIn = useSelector((s) => s.isLoggedIn);
  const user = useSelector((state) => state.user);

  const menuItems = [
    {
      label: TRANSLATIONS.menuItem.home,
      to: '/',
    },
    /* {
      label: TRANSLATIONS.menuItem.man,
      to: PERMISSIONS.IS_PRIVATE
        ? `/channel/${userCatalog?._id}/edit/products?gender=men&availability=1`
        : `/product?gender=men&availability=1`,
      children: [
        {
          label: TRANSLATIONS.menuItem.viewAllProd,
          to: '',
        },
        {
          label: TRANSLATIONS.menuItem.clothing,
          to: 'category=clothing',
          children: [
            {
              label: TRANSLATIONS.menuItem.jeans,
              to: 'subcategory=Jeans',
            },

            {
              label: TRANSLATIONS.menuItem.shirts,
              to: 'subcategory=Camicia',
            },
            {
              label: TRANSLATIONS.menuItem.sweatShirts,
              to: 'subcategory=Felpa',
            },
            {
              label: TRANSLATIONS.menuItem.trousers,
              to: 'subcategory=Pantalone',
            },
            {
              label: TRANSLATIONS.menuItem.jackets,
              to: 'subcategory=Giacca',
            },
            {
              label: TRANSLATIONS.menuItem.tracksuitPants,
              to: 'subcategory=Pantaloni+tuta',
            },
          ],
        },
        {
          label: TRANSLATIONS.menuItem.shoes,
          to: 'category=shoes',
          children: [
            {
              label: TRANSLATIONS.menuItem.mocassins,
              to: 'subcategory=Mocassino',
            },
            {
              label: TRANSLATIONS.menuItem.flatShoes,
              to: 'subcategory=Scarpe+basse',
            },
            {
              label: TRANSLATIONS.menuItem.laceUp,
              to: 'subcategory=Stringata',
            },
            {
              label: TRANSLATIONS.menuItem.sneakers,
              to: 'subcategory=Sneakers',
            },
            {
              label: TRANSLATIONS.menuItem.ankleBoots,
              to: 'subcategory=Stivaletto',
            },
            {
              label: TRANSLATIONS.menuItem.slipOn,
              to: 'subcategory=Slip-on',
            },
          ],
        },
        {
          label: TRANSLATIONS.menuItem.bags,
          to: 'category=bags',
          children: [
            {
              label: TRANSLATIONS.menuItem.crossBodyBags,
              to: 'subcategory=Tracolla',
            },
            {
              label: TRANSLATIONS.menuItem.travelBags,
              to: 'subcategory=Da+viaggio',
            },
            {
              label: TRANSLATIONS.menuItem.briefCases,
              to: 'subcategory=Portadocumenti',
            },
            {
              label: TRANSLATIONS.menuItem.tracksuitPants,
              to: 'subcategory=Pantaloni+tuta',
            },
            {
              label: TRANSLATIONS.menuItem.rucksacks,
              to: 'subcategory=Zaini',
            },
          ],
        },
        {
          label: TRANSLATIONS.menuItem.accessories,
          to: 'category=accessories',
          children: [
            {
              label: TRANSLATIONS.menuItem.sunglasses,
              to: 'subcategory=Occhiali+da+sole',
            },
            {
              label: TRANSLATIONS.menuItem.belts,
              to: 'subcategory=Cintura',
            },
            {
              label: TRANSLATIONS.menuItem.watches,
              to: 'subcategory=Orologio',
            },
            {
              label: TRANSLATIONS.menuItem.wallets,
              to: 'subcategory=Portafoglio',
            },
            {
              label: TRANSLATIONS.menuItem.eyeglasses,
              to: 'subcategory=Occhiali+da+vista',
            },
            {
              label: TRANSLATIONS.menuItem.scarves,
              to: 'subcategory=Sciarpe',
            },
          ],
        },
        {
          label: TRANSLATIONS.menuItem.underwear,
          to: '/category=underwear',
        },
      ],
    },
    {
      label: TRANSLATIONS.menuItem.woman,
      to: PERMISSIONS.IS_PRIVATE
        ? `/channel/${userCatalog?._id}/edit/products?gender=women&availability=1`
        : `/product?gender=women&availability=1`,
      children: [
        {
          label: TRANSLATIONS.menuItem.viewAllProd,
          to: '',
        },
        {
          label: TRANSLATIONS.menuItem.clothing,
          to: 'category=clothing',
          children: [
            {
              label: TRANSLATIONS.menuItem.dresses,
              to: 'subcategory=Abiti',
            },
            {
              label: TRANSLATIONS.menuItem.shirts,
              to: 'subcategory=Camicia',
            },
            {
              label: TRANSLATIONS.menuItem.leggings,
              to: 'subcategory=Leggings',
            },
            {
              label: TRANSLATIONS.menuItem.sweaters,
              to: 'subcategory=Maglia',
            },
            {
              label: TRANSLATIONS.menuItem.tops,
              to: 'subcategory=Top',
            },
            {
              label: TRANSLATIONS.menuItem.skirts,
              to: 'subcategory=Gonna',
            },
            {
              label: TRANSLATIONS.menuItem.jeans,
              to: 'subcategory=Jeans',
            },
          ],
        },
        {
          label: TRANSLATIONS.menuItem.shoes,
          to: 'category=shoes',
          children: [
            {
              label: TRANSLATIONS.menuItem.sneakers,
              to: 'subcategory=Sneakers',
            },
            {
              label: TRANSLATIONS.menuItem.ankleBoots,
              to: 'subcategory=Stivaletto',
            },
            {
              label: TRANSLATIONS.menuItem.decolletè,
              to: 'subcategory=Decollete',
            },
            {
              label: TRANSLATIONS.menuItem.sandals,
              to: 'subcategory=Sandali',
            },
            {
              label: TRANSLATIONS.menuItem.wedges,
              to: 'subcategory=Zeppa',
            },
          ],
        },
        {
          label: TRANSLATIONS.menuItem.bags,
          to: 'category=bags',
          children: [
            {
              label: TRANSLATIONS.menuItem.handBags,
              to: 'subcategory=A+mano',
            },
            {
              label: TRANSLATIONS.menuItem.shoulderBags,
              to: 'subcategory=A+spalla',
            },
            {
              label: TRANSLATIONS.menuItem.crossBodyBags,
              to: 'subcategory=Tracolla',
            },
            {
              label: TRANSLATIONS.menuItem.pochette,
              to: 'subcategory=Pochette',
            },
            {
              label: TRANSLATIONS.menuItem.shoppingBag,
              to: 'subcategory=Shopping+bag',
            },
            {
              label: TRANSLATIONS.menuItem.rucksacks,
              to: 'subcategory=Zaini',
            },
          ],
        },
        {
          label: TRANSLATIONS.menuItem.accessories,
          to: 'category=accessories',
          children: [
            {
              label: TRANSLATIONS.menuItem.belts,
              to: 'subcategory=Cintura',
            },
            {
              label: TRANSLATIONS.menuItem.necklaces,
              to: 'subcategory=Collane',
            },
            {
              label: TRANSLATIONS.menuItem.sunglasses,
              to: 'subcategory=Occhiali+da+sole',
            },
            {
              label: TRANSLATIONS.menuItem.eyeglasses,
              to: 'subcategory=Occhiali+da+vista',
            },
            {
              label: TRANSLATIONS.menuItem.wallets,
              to: 'subcategory=Portafoglio',
            },
          ],
        },
        {
          label: TRANSLATIONS.menuItem.underwear,
          to: 'category=underwear',
        },
      ],
    },
    {
      label: TRANSLATIONS.menuItem.shoes,
      to: PERMISSIONS.IS_PRIVATE
        ? `/channel/${userCatalog?._id}/edit/products?category=shoes&availability=1`
        : `/product?category=shoes&availability=1`,
      children: [
        {
          label: TRANSLATIONS.menuItem.viewAllProd,
          to: '',
        },
        {
          label: TRANSLATIONS.menuItem.sneakers,
          to: 'subcategory=Sneakers',
        },
        {
          label: TRANSLATIONS.menuItem.sandals,
          to: 'subcategory=Sandals',
        },
        {
          label: TRANSLATIONS.menuItem.decolletè,
          to: 'subcategory=Decollete',
        },
        {
          label: TRANSLATIONS.menuItem.ankleBoots,
          to: 'subcategory=Stivaletto',
        },
        {
          label: TRANSLATIONS.menuItem.mocassins,
          to: 'subcategory=Mocassino',
        },
        {
          label: TRANSLATIONS.menuItem.laceUp,
          to: 'subcategory=Stringata',
        },
      ],
    },
    {
      label: TRANSLATIONS.menuItem.bags,
      to: PERMISSIONS.IS_PRIVATE
        ? `/channel/${userCatalog?._id}/edit/products?category=bags&availability=1`
        : `/product?category=bags&availability=1`,
      children: [
        {
          label: TRANSLATIONS.menuItem.viewAllProd,
          to: '',
        },
        {
          label: TRANSLATIONS.menuItem.crossBodyBags,
          to: 'subcategory=Tracolla',
        },
        {
          label: TRANSLATIONS.menuItem.handBags,
          to: 'subcategory=A+mano',
        },
        {
          label: TRANSLATIONS.menuItem.rucksacks,
          to: 'subcategory=Zaini',
        },
        {
          label: TRANSLATIONS.menuItem.shoulderBags,
          to: 'subcategory=A+spalla',
        },
        {
          label: TRANSLATIONS.menuItem.travelBags,
          to: 'subcategory=Da+viaggio',
        },
        {
          label: TRANSLATIONS.menuItem.briefCases,
          to: 'subcategory=Portadocumenti',
        },
      ],
    },
    {
      label: TRANSLATIONS.menuItem.accessories,
      to: PERMISSIONS.IS_PRIVATE
        ? `/channel/${userCatalog?._id}/edit/products?category=accessories&availability=1`
        : `/product?category=accessories&availability=1`,
      children: [
        {
          label: TRANSLATIONS.menuItem.viewAllProd,
          to: '',
        },
        {
          label: TRANSLATIONS.menuItem.wallets,
          to: 'subcategory=Portafoglio',
        },
        {
          label: TRANSLATIONS.menuItem.sunglasses,
          to: 'subcategory=Occhiali+da+sole',
        },
        {
          label: TRANSLATIONS.menuItem.eyeglasses,
          to: 'subcategory=Occhiali+da+vista',
        },
        {
          label: TRANSLATIONS.menuItem.belts,
          to: 'subcategory=Cintura',
        },
      ],
    },*/
    {
      label: TRANSLATIONS.menuItem.moda,
      to: PERMISSIONS.IS_PRIVATE
        ? `/channel/${userCatalog?._id}/edit/products?availability=1&attributes=canale-Moda`
        : `/product?availability=1&attributes=canale-Moda`,
    },
    {
      label: TRANSLATIONS.menuItem.sexyShop,
      to: PERMISSIONS.IS_PRIVATE
        ? `/channel/${userCatalog?._id}/edit/products?availability=1&attributes=canale-sexy-shop`
        : `/product?availability=1&attributes=canale-sexy-shop`,
      hidden: user?.countryCode === 'IT' && !user?.ignoreRestrinctions,
    },
    {
      label: TRANSLATIONS.menuItem.brands,
      to: '/brands',
    },
    {
      label: TRANSLATIONS.menuItem.reseller,
      to: '/pricing',
    },
  ];

  return {
    menuItems,
  };
};

export default useSubheaderMenu;
